import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import Light from './components/others/light'

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_en from './translation/en/global.json';
import global_es from './translation/es/global.json';

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
        en: {
            global: global_en,
        },
        es: {
            global: global_es,
        },
    },
});

const App = () => {
    return (
      <I18nextProvider i18n={i18next}>
        <div>
          <Nav></Nav> {/* Agrega el selector de idioma */}
          <Light></Light>
          <Header></Header>
          <About></About>
          <Services></Services>
          {/* <Experience></Experience> */}
          <Contact></Contact>
          {/* <Footer></Footer> */}
        </div>
      </I18nextProvider>
    )
  }
  
  export default App