import React from 'react';
import "./about.css";
import ME from './../../assets/me.png';
import { useTranslation } from 'react-i18next';


const About = () => {
  const { t } = useTranslation('global');
  return (
    <section>
      
      <div className='container' id='about'>
      <h3 className='section__title'>{t("about.title")}</h3>
      <div className='about__container'>
        
        <div className='content'>
          <p>{t("about.desciptionPartOne")}</p><br />

          <p>{t("about.desciptionPartTwo")}</p><br />

          <p>{t("about.desciptionPartThree")}</p>

        </div>
        
        <div className="me">
          <img src={ME} alt="me" />
        </div>
      </div>

      </div>


    </section>

  )
}

export default About