import React from 'react'
import "./nav.css"
import {BiWorld} from 'react-icons/bi'
import {TbMoonStars} from 'react-icons/tb'
import { useTranslation } from 'react-i18next';

const Nav = () => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  let logo = '{OP}';
  return (
    
    <nav className='container nav__container' id='Nav'>
      <div  className='wrapper'>
      <h1 className='logo'><a href="#Nav" id='logo'>{logo}</a></h1>
      <ul className='options'>
        <li className="tooltip-trigger">
          <BiWorld size={35} />
          <div className="tooltip">
            <ul className="submenu">
              <li onClick={() => handleChangeLanguage('es')}>Español</li>
              <li onClick={() => handleChangeLanguage('en')}>English</li>
            </ul>
          </div>
        </li>
        <li>
          <TbMoonStars size={35} />
        </li>
      </ul>

      </div>
      

    </nav>
  )
}

export default Nav