import React from 'react'
import "./header.css"
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'
import Ellipse from './Ellipse'
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation('global'); 



  return (
    <header id='header'>
      <div class="dot-pattern"></div>
      
      <div className="container header__container" id='Header'>
        <div className='presentation'>
          <h5 className='greeting'>{t('header.greeting')}</h5>
          <h1 className='name'>Oleksandr Pasternak;</h1>
          <h1 className="text-light">Fullstack Developer </h1>
          <p className="description">{t('header.menssage')}</p>
          <a className='me-information' href="#about">{t('header.aboutMeHeader')} &gt;</a>
          <CTA></CTA>
        </div>
          
        <div className='ellipse_efect'>
          <Ellipse></Ellipse>
        </div>

        {/* <HeaderSocials></HeaderSocials> */}

        <a href="#contact" className='scroll__down'>Scroll down</a>
      </div>
    </header>

  )
}

export default Header

// Versátil con sólidos conocimientos técnicos 
//           en frontend y backend. Buen habilidad en resolución de problemas, trabajo en equipo y 
//           seguir aprendiendo, de forma autodidacta como de otros.