import React from 'react'
import CS from './../../assets/css-logo.png'
import SP from './../../assets/spring-logo.png'
import RC from './../../assets/react-logo.png'
import JS from './../../assets/JS-logo.png'
import MDB from './../../assets/mongoDB-logo.png'
import NJS from './../../assets/nodeJS-logo.png'





const Ellipse = () => {
  return (
    
    <dir>
      <div className='marco'></div>
      <div class="ellipse">
        <div class="circle">
          <img src={CS} alt="" />
        </div>
        <div class="circle">
            <img src={SP} alt="" />
        </div>
        <div class="circle">
            <img src={RC} alt="" />
        </div>
        <div class="circle">
          <img src={NJS} alt="" />
        </div>
        <div class="circle">
            <img src={MDB} alt="" />
        </div>
        <div class="circle">
            <img src={JS} alt="" />
        </div>

    </div>
    </dir>
    
  )
}

export default Ellipse