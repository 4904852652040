import React from 'react'
import "./light.css"

const light = () => {
  return (
    <div>
        <div class="gradient one"></div>
        <div class="gradient two"></div>
        <div class="gradient three"></div>
        <div class="gradient four"></div>


    </div>
    
    
  )
}

export default light