import React from 'react'
import "./contact.css"
import { useRef } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import { BsLinkedin } from 'react-icons/bs'
import { BsGithub } from 'react-icons/bs'


const Contact = () => {
  const { t } = useTranslation('global');

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_29czn2m', 'template_pdb8jgw', form.current, 'NjPNvqJrROAUEzV7D')

      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };
  return (
    <section id='contact'>


      <div className='container'>
        <div className=''>
          <h2 className='section__title'>{t("contact.title")}</h2>
        </div>
        <div className=' contact__container'>
          <div className='Social_Container'>
            <div className='uno contact__options'>
              <a href="https://www.linkedin.com/in/oleksandr-pasternak/" target='_blank'><BsLinkedin size={40} /></a>
            </div>
            <div className='dos contact__options'>
              <a href="https://github.com/0Pasternak" target='_blank' ><BsGithub size={40} /></a>
            </div>
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name="name" placeholder='Your Full Name' required />
            <input type="email" name="email" placeholder='Your Email' required />
            <textarea type="message" placeholder='Your Messages' rows='7' name="email" required> </textarea>
            <button type='submit' className='btn btn-primary'>Send Message</button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Contact