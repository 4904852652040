import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div>
        <App />
    </div>
);

//ReactDOM.render(<App/>, document.querySelector("#root"))

