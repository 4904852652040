import React, { useState } from 'react';
import './services.css';
import {AiFillHtml5} from 'react-icons/ai'
import {DiCss3} from 'react-icons/di'
import {DiJavascript} from 'react-icons/di'
import {FaBootstrap} from 'react-icons/fa'
import {BiLogoReact} from 'react-icons/bi'
import {BiLogoTypescript} from 'react-icons/bi'
import {DiSass} from 'react-icons/di'
import {BiLogoJava} from 'react-icons/bi'
import {BiLogoSpringBoot} from 'react-icons/bi'
import {SiDotnet} from 'react-icons/si'
import {SiCsharp} from 'react-icons/si'
import {SiPhp} from 'react-icons/si'
import {BiLogoMongodb} from 'react-icons/bi'
import {TbSql} from 'react-icons/tb'

import {BsGit} from 'react-icons/bs'
import {BsWordpress} from 'react-icons/bs'
import {BiLogoDocker} from 'react-icons/bi'
import {FaJira} from 'react-icons/fa'
import {DiScrum} from 'react-icons/di'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation('global'); 

  return (
    <div className='container services__container' id='Services'>
          <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar]}
      spaceBetween={5}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      initialSlide={1}
    >
      <SwiperSlide>
      <div className='cuadrado'>
        <h2 className='title-slide'>FRONTEND</h2>
            <p className='frontend-text'>{t("services.frontend")}</p>
            <ul className='front'>
              <li>
                <AiFillHtml5 className='service-icon'/>
                <p>HTML</p>
              </li>
              <li>
                <DiCss3 className='service-icon'/>
                <p>CSS</p>
              </li>
              <li>
                <DiJavascript className='service-icon'/>
                <p>JavaScript</p>
              </li>
              <li>
                <FaBootstrap className='service-icon'/>
                <p>Bootstrap</p>
              </li>
            </ul>
            <ul className='front'>
              <li>
                <BiLogoReact className='service-icon'/>
                <p>React</p>
              </li>
              <li>
                <BiLogoTypescript className='service-icon'/>
                <p>Typescript</p>
              </li>
              <li>
                <DiSass className='service-icon'/>
                <p>Sass</p>
              </li>
            </ul>

          </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='cuadrado'>
      <h2 className='title-slide'>BACKEND</h2>
            <p className='frontend-text'>{t("services.backend")}</p>
            <ul className='front'>
              <li>
                <BiLogoJava className='service-icon'/>
                <p>JAVA</p>
              </li>
              <li>
                <BiLogoSpringBoot className='service-icon'/>
                <p>Spring Boot</p>
              </li>
              <li>
                <SiCsharp className='service-icon'/>
                <p>C#</p>
              </li>
              <li>
                <SiDotnet className='service-icon'/>
                <p>.NET</p>
              </li>
            </ul>
            <ul className='front'>
              <li>
                <SiPhp className='service-icon'/>
                <p>PHP</p>
              </li>
              <li>
                <BiLogoMongodb className='service-icon'/>
                <p>Mongodb</p>
              </li>
              <li>
                <TbSql className='service-icon'/>
                <p>Sql</p>
              </li>
            </ul>
          </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className='cuadrado'>
        <h2 className='title-slide'>OTHERS</h2>
        
            <p className='frontend-text'>{t("services.others")}</p>
            <ul className='front'>
              <li>
                <BsGit className='service-icon'/>
                <p>Git</p>
              </li>
              <li>
                <BsWordpress className='service-icon'/>
                <p>Wordpress</p>
              </li>
              <li>
                <BiLogoDocker className='service-icon'/>
                <p>Docker</p>
              </li>
            </ul>
            <ul className='front'>
            <li>
                <FaJira sclassName='service-icon'/>
                <p>Jira</p>
              </li>
            <li>
                <DiScrum className='service-icon'/>
                <p>Scrum</p>
              </li>
            </ul>
          </div>
      </SwiperSlide>      
    </Swiper>

    </div>



  );
}

export default Services;
