import React from 'react'
import CV from './../../assets/CV.pdf'
import { useTranslation } from 'react-i18next';

const CTA = () => {
  const { t } = useTranslation('global');
  return (
    <div className='cta'>
      <a /*href={CV}*/ download className='btn btn-secondary'>{t('header.ctaBtnOne')}</a>
      <a href="#contact" className='btn btn-primary'>{t('header.ctaBtnTwo')}</a>
    </div>

  )
}

export default CTA